import React from "react"
import { Link } from "gatsby"
import '@styles/components/bottom-cta.scss'
import RightArrowIcon from "@images/svg/icon-right-arrow_white_solid.svg"
export default () => (
  <section class="bottom-cta">
    <div class="bottom-cta__container">
      <p class="heading2-lead">CONTACT</p>
      <h2 class="heading2">オンライン家庭教師のお申し込み</h2>
      <p class="txt-lh_225">「サービスの詳細が知りたい」「授業の進め方やカリキュラムの相談がしたい」などの<br/>ご質問も受け付けております。お気軽にご連絡ください。</p>
      <p class="btn btn--primary"><Link to="/contact">申し込みはこちら<RightArrowIcon /></Link></p>
    </div>
  </section>
)